import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useNotificationCenter } from "react-toastify/addons/use-notification-center"
import useApi from '@/hooks/useApi';
import { useAuth } from '@/hooks/useAuth';
import parse from 'html-react-parser';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { de } from 'date-fns/locale';
import { useNavigate } from 'react-router-dom';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { useChannel } from '@harelpls/use-pusher';
import { toast } from 'react-toastify';
import { Bell, BellOff, Check, CheckCheck, Clock } from 'lucide-react';
import { ScrollArea } from '../ui/scroll-area';
import { Sheet, SheetContent, SheetDescription, SheetFooter, SheetHeader, SheetTitle, SheetTrigger } from '../ui/sheet';
import { Button } from '../ui/button';
import { Alert, AlertDescription, AlertTitle } from '../ui/alert';
import { cn } from '@/lib/utils';

const NotificationCenter = () => {
    const api = useApi();
    const { user, publishers, updatePublisherTerms } = useAuth();
    const { notifications, add, markAsRead } = useNotificationCenter();
    const [open, setOpen] = useState(false);
    const dropdownRef = useRef();
    const navigate = useNavigate();

    const channel = useChannel('private-App.Models.User.' + user.id);
    
    useEffect(() => {
        api.get('user/notifications').then((response) => {
            response.data.forEach((notification) => {
                add({
                    id: notification.id,
                    content: parse(notification.data.title),
                    createdAt: parseISO(notification.created_at),
                    containerId: 'notification-center',
                    data: {
                        link: notification.data?.link
                    }
                });
            });
        });

        
    }, []);

    useEffect(() => {
        if (channel) {
            channel.bind('Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', (data) => {
                toast(({ closeToast, toastProps }) => (
                    <div>{ parse(data.title) }</div>
                ), {
                    type: 'success',
                    toastId: data.id,
                    containerId: 'notification-center',
                    data: {
                        link: data.link
                    },
                    onClick: () => {
                        if (data.link) {
                            markAsRead(data.id);
                            navigate(data.link);
                        }
                    }
                });
            });

            channel.bind('App\\Events\\Publisher\\PublisherTermsChanged', (data) => {
                updatePublisherTerms(data.publisher_id, data.terms);
            });
        }
    }, [channel]);

    useOnClickOutside(dropdownRef, () => setOpen(false));

    const unreadNotifications = useMemo(() => notifications.filter((notification) => !notification.read && notification.containerId === 'notification-center'), [notifications]);
    const timeAgo = date => formatDistanceToNow(new Date(date), { locale: de, addSuffix: true });

    const markRead = (id) => {
        markAsRead(id);

        api.post('user/notifications/mark-as-read', { id });
    }

    return (
        <div className="relative ml-auto flex items-center">
            <Sheet>
                <SheetTrigger asChild>
                    <Button variant='ghost' className='relative size:10 lg:size-12 p-0 rounded-full'>
                        <Bell className={cn('size-6 lg:size-7', unreadNotifications.length > 0 && 'animate__animated animate__swing animate__infinite')} />
                        { unreadNotifications.length > 0 && (
                            <div className="bg-red-600 text-white rounded-full w-5 h-5 text-center text-sm absolute right-0 top-0">{unreadNotifications.length}</div>
                        )}
                    </Button>
                </SheetTrigger>
                <SheetContent className='w-10/12 max-w-full lg:max-w-[600px] flex flex-col'>
                    <SheetHeader>
                        <SheetTitle className='flex items-center'>
                            <Bell className='size-6 mr-2' />
                            Benachrichtigungen
                        </SheetTitle>
                        <SheetDescription>{ unreadNotifications.length } ungelesen</SheetDescription>
                    </SheetHeader>
                        { unreadNotifications.length === 0 ? (
                            <div className="p-3 flex-1 flex justify-center text-lg items-center text-center text-muted-foreground">
                                <div>
                                    <BellOff className='size-10 mx-auto mb-6' />
                                    Sie haben keine Benachrichtigungen.
                                </div>
                            </div>
                        ) : (
                            <ScrollArea className='flex-1'>
                                <div className='flex flex-col flex-1 gap-2'>
                                    { unreadNotifications.map((notification, index) => (
                                        <Alert key={index} className={cn('flex items-center gap-4', notification.data?.link && 'cursor-pointer hover:bg-muted')} onClick={(e) => {
                                            if (notification.data?.link) {
                                                e.preventDefault();
                                                markRead(notification.id);
                                                navigate(notification.data.link);
                                            }
                                        }}>
                                            <div>
                                                <AlertTitle className='font-normal'>{ notification.content }</AlertTitle>
                                                <AlertDescription className='flex items-center'>
                                                    <Clock className='size-3 mr-1'/>
                                                    { timeAgo(notification.createdAt) }
                                                </AlertDescription>
                                            </div>
                                            <Button variant='outline' className='ml-auto size-8 p-0 rounded-full' onClick={(e) => {
                                                e.stopPropagation();
                                                markRead(notification.id)
                                            }}>
                                                <Check className='size-4' strokeWidth={3} />
                                            </Button>
                                        </Alert>
                                    )) }
                                </div>
                            </ScrollArea>
                        ) }
                    { unreadNotifications.length > 0 && <SheetFooter>
                        <Button variant='secondary' onClick={() => {
                            api.post('user/notifications/mark-all-as-read')
                            notifications.forEach((notification) => {
                                markAsRead(notification.id);
                            });
                        }}>
                            <CheckCheck className='size-4 mr-2' />
                            Alle als gelesen markieren
                        </Button>
                    </SheetFooter> }
                </SheetContent>
            </Sheet>
        </div>
    )
}

export default NotificationCenter;