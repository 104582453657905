export default [
    'ab',
	'die',
	'der',
	'und',
	'in',
	'zu',
	'den',
	'das',
	'nicht',
	'von',
	'sie',
	'ist',
	'des',
	'sich',
	'mit',
	'dem',
	'dass',
	'er',
	'es',
	'ein',
	'ich',
	'auf',
	'so',
	'eine',
	'auch',
	'als',
	'an',
	'nach',
	'wie',
	'im',
	'für',
	'einen',
	'um',
	'werden',
	'mehr',
	'zum',
	'aus',
	'ihrem',
	'style',
	'oder',
	'neue',
	'spieler',
	'können',
	'wird',
	'sind',
	'ihre',
	'einem',
	'of',
	'du',
	'sind',
	'einer',
	'über',
	'alle',
	'neuen',
	'bei',
	'durch',
	'kann',
	'hat',
	'nur',
	'noch',
	'zur',
	'gegen',
	'bis',
	'aber',
	'haben',
	'vor',
	'seine',
	'ihren',
	'jetzt',
	'ihr',
	'dir',
	'etc',
	'bzw',
	'nach',
	'deine',
	'the',
	'warum',
	'machen',
	'sowie',
	'am'
];