import { useState } from "react";
import { useAuth } from "../hooks/useAuth"
import PasswordStrengthBar from 'react-password-strength-bar';
import useApi from '@/hooks/useApi';
import { toast } from 'react-toastify';
import { Dialog, DialogContent } from "./ui/dialog";
import { Label } from "./ui/label";
import { Input } from "./ui/input";
import { Button } from "./ui/button";

const ProfileDialog = ({ isOpen, onClose }) => {
    const auth = useAuth();
    const api = useApi();
    const [user, setUser] = useState({
        name: auth.user.name,
        email: auth.user.email,
        password: '',
        password_confirmation: ''
    });
    const [isSaving, setIsSaving] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSaving(true);

        api.patch('user', user).then((result) => {
            setIsSaving(false);
            auth.setUser(result.data);
            onClose();
            toast.success('Ihre Konto-Änderungen wurden erfolgreich gespeichert.');
        });
    };
    
    return <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="max-w-4xl p-0 xl:flex">
            <aside className="px-8 py-10 justify-center xl:w-80 flex-none bg-slate-200 rounded-lg z-20 self-stretch">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className='h-10 w-10 text-slate-400 mb-5'>
                    <path fillRule="evenodd" d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" clipRule="evenodd" />
                </svg>
                <h2 className="text-xl font-bold text-slate-700 leading-tight mb-2">Konto bearbeiten</h2>
                <p className="text-base text-slate-500 leading-snug xl:mb-10">
                    Hier haben Sie die Möglichkeit, Ihre Kontoeinstellungen und persönlichen Daten zu bearbeiten.
                </p>
            </aside>
            <form className="flex-1 px-8 py-10" onSubmit={handleSubmit}>
                <Label>Ihr Name:</Label>
                <Input type="text" value={user.name} required onChange={e => setUser({ ...user, name: e.target.value })} className="mb-6" />
                <Label>Ihre E-Mail-Adresse:</Label>
                <Input type="email" value={user.email} required onChange={e => setUser({ ...user, email: e.target.value })} className="mb-6" />
                <Label>Neues Passwort:</Label>
                <Input type="password" value={user.password} onChange={e => setUser({ ...user, password: e.target.value })} />
                { (user.password) ? <PasswordStrengthBar
                        className='mt-2 mb-6'
                        password={user.password}
                        scoreWords={['sehr schwach', 'schwach', 'okay', 'gut', 'stark']}
                        shortScoreWord="sehr schwach"
                        barColors={['#ddd', '#ef4836', '#f6b44d', '#2b90ef', '#25c281']}
                        scoreWordClassName="font-medium" />
                    : <p className="text-sm text-slate-500 mt-1">Feld leer lassen, wenn das Passwort nicht geändert werden soll.</p> }
                <Label>Passwort wiederholen:</Label>
                <Input type="password" required={user.password != ''} value={user.password_confirmation} onChange={e => setUser({ ...user, password_confirmation: e.target.value })} className="w-full border-slate-200 rounded-md" />
                { (user.password && user.password_confirmation && user.password !== user.password_confirmation) && <p className="text-sm text-red-500 mt-1">Die Passwörter stimmen nicht überein.</p> }
                <div className="flex items-center justify-between mt-10">
                    <Button type="button" variant="outline" onClick={onClose}>Abbrechen</Button>
                    <Button type="submit" disabled={user.password !== user.password_confirmation || isSaving}>{ isSaving ? 'Bitte warten' : 'Speichern' }</Button>
                </div>
            </form>
        </DialogContent>
    </Dialog>
}

export default ProfileDialog;