import { cn } from "@/lib/utils"

interface LoaderProps {
    text?: string,
    classNames?: string,
    size?: number,
    color?: string,
    orientation?: 'vertical' | 'horizontal'
}

const Loader = ({
    text = 'Wird geladen...',
    size = 30,
    color = '#475569',
    classNames = '',
    orientation = 'horizontal'
}: LoaderProps) => (
    <div className={cn('w-full h-full flex justify-center items-center text-xl', classNames)}>
        <div className={cn(
            'flex items-center',
            orientation === 'vertical' && 'flex-col'
        )} style={{
            gap: size * 0.75
        }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={size} height={size} className="animate-spin"><path fill="none" d="M0 0h24v24H0z"/><path fill={color} d="M12 3a9 9 0 0 1 9 9h-2a7 7 0 0 0-7-7V3z"/></svg>
            {/* <div className="book-loader" style={{
                width: size * 0.70,
                height: size,
                perspective: size * 2,
                // marginRight: text ? size : 0
            }}>
                <div style={{ backgroundColor: color }}></div>
                <div style={{ backgroundColor: color }}></div>
                <div style={{ backgroundColor: color }}></div>
                <div style={{ backgroundColor: color }}></div>
            </div> */}
            { text && <div>{ text }</div> }
        </div>
    </div>
)

export default Loader;