import axios, { type AxiosRequestHeaders, type AxiosRequestTransformer, type InternalAxiosRequestConfig } from 'axios';
import { useAuth } from './useAuth';
import { formatISO } from 'date-fns';

const apiBase = import.meta.env.VITE_API_BASE;

const dateTransformer: AxiosRequestTransformer = (data: any, headers: AxiosRequestHeaders) => {
  if (data instanceof Date) {
    return formatISO(data);
  }
  if (Array.isArray(data)) {
    return data.map((val) => dateTransformer(val, headers));
  }
  if (Object.prototype.toString.call(data) === '[object Object]') {
    return Object.fromEntries(
      Object.entries(data).map(([key, val]) => [key, dateTransformer(val, headers)]),
    );
  }
  return data;
};

export default () => {

    const axiosInstance = axios.create({
      transformRequest: [dateTransformer].concat(axios.defaults.transformRequest as AxiosRequestTransformer[]),
      baseURL: apiBase,
      paramsSerializer: {
        serialize: (params) => {
          Object.keys(params).forEach((key) => {
            if (params[key] instanceof Date) {
              params[key] = formatISO(params[key]);
            }
          });

          return (new URLSearchParams(params)).toString();
        }
      }
    });

    const { logout } = useAuth();

    axiosInstance.interceptors.request.use((config) => {
        const token = localStorage.getItem('token');
        config.headers.Authorization = `Bearer ${token}`;

        // filter empty request params
        if (config.params) {
            Object.keys(config.params).forEach((key) => {
                if (config.params[key] === null || config.params[key] === undefined || config.params[key] === '') {
                    delete config.params[key];
                }
            });
        }

        return config;
    });

    // convert all UTC dates in query params to local time
    // axiosInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    //   console.log(config);
    //   config.paramsSerializer = params => {
    //     const localParams = { ...params };
    //     console.log(localParams);
    //     Object.keys(localParams).forEach((key) => {
    //       if (localParams[key] instanceof Date) {
    //         localParams[key] = formatISO(localParams[key]);
    //       }
    //     });
    //     console.log(localParams);


    //     return axios.defaults.paramsSerializer(localParams);
    //   }

    //   return config;
    // })

    axiosInstance.interceptors.response.use(response => response, (error) => {
        if (error.response?.status === 401) {
            logout(false);
        }

        return Promise.reject(error);
    });

    return axiosInstance;
}