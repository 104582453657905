import React, { useEffect, useState } from 'react';
import { Link, NavLink, useOutletContext } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import BookStatus from '../../components/BookStatus';

export default () => {
    const { person, books } = useOutletContext();
    const { token, publishers } = useAuth();

    const coverURL = (book) => {
        const url = new URL(`${ import.meta.env.VITE_API_BASE }/books/${book.id}/cover`);

        if (token) {
            url.searchParams.append('token', token);
        }

        url.searchParams.append('width', 400);
        url.searchParams.append('height', 600);

        return url;
    }

    return (<div>
        <h2 className="text-3xl font-medium mb-5">Bücher von { person.alias ? person.alias : (
            person.company ? person.company : person.firstname + ' ' + person.lastname
        ) }</h2>

        <div className="xl:grid grid-cols-3 gap-6">
            { books.map(book => (
                <Link to={'/books/view/' + book.id} key={book.id} className="bg-slate-50 shadow rounded-md mt-3 overflow-hidden transition hover:scale-105 duration-300 hover:shadow-xl">
                    <div className='flex bg-slate-200/40 border-b border-slate-200 w-full h-72 p-6 items-center justify-center'>
                        { book.cover ? (
                            <img src={coverURL(book)} className="h-full w-auto transition-all rounded duration-500 relative z-0" loading="lazy" />
                        ) : (
                            <div className="h-full flex justify-center rounded items-center text-lg bg-slate-200 text-slate-400 text-center z-0" style={{ aspectRatio: '210/297'}}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-20 w-20 mb-20 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                                </svg>
                            </div>
                        )}
                    </div>
                    <div className="p-4 xl:p-6">
                        <Link to={'/books/view/' + book.id} className="text-lg text-slate-600 inline-block min-h-[50px] font-medium leading-tight mb-1">{ book.title }</Link>
                        <div className='mt-4'>
                            <BookStatus status={book.status} style='dot' />
                        </div>
                        <div className="space-y-1 border-t border-slate-300 text-slate-500 mt-4 pt-4">
                            <div className="flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="mr-3 w-4 h-4"><path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" /></svg>
                                { book.authors.join(", ") }
                            </div>
                            <div className="flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="mr-3 w-4 h-4" viewBox="0 0 24 24" fill="currentColor"><path fill="none" d="M0 0h24v24H0z"/><path d="M3 18.5V5a3 3 0 0 1 3-3h14a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H6.5A3.5 3.5 0 0 1 3 18.5zM19 20v-3H6.5a1.5 1.5 0 0 0 0 3H19zM5 15.337A3.486 3.486 0 0 1 6.5 15H19V4H6a1 1 0 0 0-1 1v10.337z"/></svg>
                                { book.isbn13 ?? book.isbn10 }
                            </div>
                            <div className="flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="mr-3 w-4 h-4" viewBox="0 0 24 24" fill="currentColor"><path fill="none" d="M0 0h24v24H0z"/><path d="M21 19h2v2H1v-2h2V4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v15h4v-8h-2V9h3a1 1 0 0 1 1 1v9zM5 5v14h8V5H5zm2 6h4v2H7v-2zm0-4h4v2H7V7z"/></svg>
                                { publishers.find(publisher => publisher.id === book.publisher_id)?.name }
                            </div>
                            
                        </div>
            
                    </div>
                </Link>
            )) }
        </div>
        
    </div>)
}