import { KeyboardEvent, ReactElement, useEffect, useRef, useState } from "react";
import { Input } from "./ui/input";
import { Popover, PopoverContent } from "./ui/popover";
import { cn } from "@/lib/utils";
import { PopoverAnchor } from "@radix-ui/react-popover";
import Loader from "./Loader";
import { Command, CommandEmpty, CommandGroup, CommandItem, CommandList, CommandSeparator } from "./ui/command";

let timer = null;

interface AutocompleteProps {
    onSearch: (term: string) => Promise<any[]>,
	onSelect: (item:  any) => (Promise<void> | void),
	renderItem: (item: any) => ReactElement,
	groups?: {
		[key: string]: (item: any) => boolean;
	},
    
    debounceTime?: number,
    
	placeholder?: string,
	icon?: ReactElement,
	loaderSize?: number,
	noResultsMessage?: string | ReactElement,

	inputClassName?: string,
	containerClassName?: string,
	popoverAlign?: "start" | "end" | "center",
}

const Autocomplete = ({
	onSearch,
	onSelect,
	renderItem,
	groups,

	debounceTime = 150,
	
	placeholder = 'Suchbegriffe eingeben...',
	icon,
	loaderSize = 20,
	noResultsMessage = "Keine Ergebnisse",

	inputClassName = "",
	containerClassName = "",
	popoverAlign = "center",
} : AutocompleteProps) => {
    const [items, setItems] = useState([]);
    const [isSearching, setSearching] = useState(false);
	const [isOpen, setOpen] = useState(false);
	const [highlightedIndex, setHighlightedIndex] = useState<number | false>(false);

	const inputRef = useRef<HTMLInputElement>();
	const highlightRef = useRef<HTMLDivElement>();

	const handleSpecialKeys = (e:KeyboardEvent) => {
		const input = (e.target as HTMLInputElement);
		switch(e.code) {
		case "ArrowDown":
			if(!isOpen) {
				setOpen(true);
			}
			if(!e.altKey) {
				if(!isOpen || highlightedIndex === false) {
					highlight(0);
				} else {
					highlight(highlightedIndex + 1)
				}
			}
			e.preventDefault();
			break;
		case "ArrowUp":
			if(!isOpen || highlightedIndex === false) {
				if(!isOpen) {
					setOpen(true);
				}
				highlight(items.length-1)
			} else {
				highlight(highlightedIndex - 1)
			}
			e.preventDefault();
			break;

		case "Enter":
			if(isOpen && highlightedIndex !== false) {
				select(input, items[highlightedIndex]);
			}
			e.preventDefault();
			break;

		case "Escape":
			if(isOpen) {
				close();
			} else {
				input.value = "";
				setItems([]);
			}
			e.preventDefault();
			break;
		}
	}

	const highlight = async (index: number) => {
		if(items.length === 0) return;
		const nextIndex = ((index % items.length) + items.length) % items.length;
		setHighlightedIndex(nextIndex)
	}

	useEffect(() => {
		if(items.length === 0) return;
		if(highlightedIndex !== false && highlightedIndex >= 0 && highlightedIndex < items.length) {
			const selectedOption = highlightRef.current;
			if(selectedOption) {
				selectedOption.scrollIntoView({block:"nearest"});
			}
		}
	}, [highlightedIndex])

	const close = () => {
		setOpen(false);
		setHighlightedIndex(false);
	}
	
	const select = (input: HTMLInputElement, item: any) => {
		close();
		onSelect(item);	
		input.value = "";
		setItems([]);
		input.blur();
	}	

	const handleTyping = (e) => {
		if (timer) {
			clearTimeout(timer);
			setSearching(false);
		}

		let value = e.target.value;
		if (value.trim() === "") {
			close();
			setItems([]);
		} else {
			setSearching(true);
			timer = setTimeout(async () => {
				const results = await onSearch(value);
				if(groups){
					const ordered = [];
					const groupFilters = Object.values(groups);  
					for(const filter of groupFilters){
						ordered.push(...results.filter(filter));
					}
					// NOTE: each item should belong to exactly one group
					if(ordered.length < results.length){
						const missing = results.filter(item => !ordered.includes(item));
						console.assert(false, "Some items do not belong to any group:", missing)
					}
					if(ordered.length > results.length){
						const doubles = Array.from(new Set( results.filter(item => groupFilters.filter(f => f(item)).length > 1)));
						console.assert(false, "Some items belong to multiple groups:",  doubles)
					}
					setItems(ordered);
				} else {
					setItems(results);
				}
				setSearching(false);
				setOpen(true);
			}, debounceTime);
		}
	};

	const wrapItem = (item: any, index: number) => (
		<CommandItem asChild>
			<div id={"autocomplete-list-item" + index}
				role="list-item"
				aria-selected={isOpen && index === highlightedIndex}
				
				onClick={() => select(inputRef.current, item)}
				className="cursor-pointer hover:bg-accent w-full aria-selected:bg-accent"
				key={index}
				ref={index === highlightedIndex ? highlightRef : null}
			>
				{renderItem(item)}
			</div>
		</CommandItem>
	);

    return (
		<Popover open={isOpen}>
			<PopoverAnchor className="relative w-full">
				{icon && <div className="absolute h-6 top-1/2 -translate-y-1/2 left-4">
					{icon}
				</div>}
				<Input type="text"
					id="autocomplete-combobox"
					ref={inputRef}

					role="combobox"
					aria-controls="autocomplete-listbox"
					aria-expanded={isOpen}
					aria-activedescendant={"autocomplete-list-item"+highlightedIndex}
					aria-haspopup="listbox"

					spellCheck={false}
					autoComplete="off"
					className={cn("w-full p-4 pr-8 placeholder:pr-8", inputClassName, {"pl-12" : icon})}
					placeholder={placeholder}

					onKeyDown={handleSpecialKeys}
					onChange={handleTyping}
					onBlur={(e) => {
						if(isOpen) {
							const container = document.getElementById("autocomplete-container");
							if(!container.contains(e.relatedTarget)){
								close();
							}
						}
					}}
					onFocus={() => setOpen(items.length > 0 || isOpen)}
				/>
				{isSearching && <div className="w-6 absolute top-1/2 right-2 -translate-y-1/2">
					<Loader size={loaderSize} text=""/>
				</div>}
			</PopoverAnchor>
			<PopoverContent asChild
				className="max-w-[calc(100vw-1rem)]"
				onOpenAutoFocus={e => e.preventDefault()}
				id="autocomplete-container"
				align={popoverAlign}
				onMouseLeave={(e) => {
					if(document.activeElement !== inputRef.current){
						const container = document.getElementById("autocomplete-container");
						const rect = container.getBoundingClientRect();
						const outside = rect.left > e.clientX || e.clientX > rect.right || rect.top > e.clientY || e.clientY > rect.bottom;
						if(outside) close();
					}
				}}
			>
				<Command className="p-0 w-full">
					<CommandList id="autocomplete-listbox"
						role="listbox"
						className={cn(
						"scrollbar-thin scrollbar-track-muted scrollbar-thumb-muted-foreground", 
						"min-h-10 p-1 max-h-[90vh]",
						containerClassName)}
					>
						{ (!isSearching && items.length === 0) && 
							<CommandEmpty id={"autocomplete-list-item"}
								role="list-item"
								aria-selected={isOpen}>
								{noResultsMessage}
							</CommandEmpty>
						}
						{groups
							? Object.entries(groups)
								.map(([key, filter]) => [key, items.filter(filter)])
								.filter(([_, groupItems]) => groupItems.length !== 0)
								.map(([key, groupItems], index) => <>
									{index !== 0 && <CommandSeparator/>}
									<CommandGroup heading={key}>
										{(groupItems as any[]).map(item => wrapItem(item, items.findIndex((i) => i == item)))}
									</CommandGroup>
								</>)
							: <CommandGroup>
								{items.map(wrapItem)}
							</CommandGroup>
						}
					</CommandList>
				</Command>
			</PopoverContent>
		</Popover>
    )
}

export default Autocomplete;