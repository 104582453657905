import { format, parseISO } from 'date-fns';

interface FormatCurrencyOptions {
    currency?: boolean;
}

const formatCurrency = (value: number, currency: string = 'EUR', options?: FormatCurrencyOptions) => {
    return new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency,
        minimumFractionDigits: 2,
        ...options
    }).format(value);
}

const formatDate = (date: Date|string, dateFormat: string = 'dd.MM.yyyy') => {
    if (typeof date === 'string') {
        date = parseISO(date);
    }

    return format(date, dateFormat);
}

const formatDateTime = (date, dateFormat) => {
    if (typeof date === 'string') {
        date = parseISO(date);
    }

    return format(date, dateFormat ?? 'dd.MM.yyyy HH:mm:ss');
}

const formatWeight = (value) => {
    if (value === null || value === undefined) {
        return '';
    }
    
    let unit = 'gram';

    if (value >= 1000) {
        value = value / 1000;
        unit = 'kilogram';
    }

    return value.toLocaleString('de-DE', {
        style: 'unit',
        maximumFractionDigits: 2,
        unit,
        unitDisplay: 'short'
    });
}

export {
    formatCurrency,
    formatDate,
    formatDateTime,
    formatWeight
}