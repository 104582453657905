import React, { useState, useEffect } from 'react';
import Bugsnag from '@bugsnag/js'
import { toast } from 'react-toastify';
import { useAuth } from '../hooks/useAuth';
import useApi from '../hooks/useApi';
import html2canvas from 'html2canvas';
import Loader from './Loader';
import { Dialog, DialogContent } from './ui/dialog';
import { Textarea } from './ui/textarea';
import { Button } from './ui/button';

const BugDialog = ({ isOpen, onClose }) => {
    const ref = React.createRef();
    const [description, setDescription] = useState('');
    const [attachScreenshot, setAttachScreenshot] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { user } = useAuth();
    const api = useApi();

    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsSubmitting(true);

        const subject = `Mitteilung von ${user.name} (${user.email})`;

        const canvas = await html2canvas(document.body, {
            ignoreElements: (element) => element.id === 'bug-report-modal' || element.classList.contains('dialog-overlay'),
        });

        api.post('bugreport', {
            subject,
            description,
            url: window.location.href,
            screenshot: canvas.toDataURL('image/png')
        });
        
        toast.success('Ihre Anfrage wurde erfolgreich übermittelt. Vielen Dank für Ihre Mithilfe!', {
            autoClose: 5000
        });

        handleClose();
        setIsSubmitting(false);
    };

    const handleClose = () => {
        onClose();
        setDescription('');
    };
    
    return <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent id='bug-report-modal' className="!p-0 bug-report-modal xl:flex max-w-5xl">
            <aside className="px-8 py-10 justify-center xl:w-80 flex-none bg-slate-200 rounded-lg z-20">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className='h-10 w-10 text-slate-400 mb-5'><path d="M9.97308 18H14.0269C14.1589 16.7984 14.7721 15.8065 15.7676 14.7226C15.8797 14.6006 16.5988 13.8564 16.6841 13.7501C17.5318 12.6931 18 11.385 18 10C18 6.68629 15.3137 4 12 4C8.68629 4 6 6.68629 6 10C6 11.3843 6.46774 12.6917 7.31462 13.7484C7.40004 13.855 8.12081 14.6012 8.23154 14.7218C9.22766 15.8064 9.84103 16.7984 9.97308 18ZM14 20H10V21H14V20ZM5.75395 14.9992C4.65645 13.6297 4 11.8915 4 10C4 5.58172 7.58172 2 12 2C16.4183 2 20 5.58172 20 10C20 11.8925 19.3428 13.6315 18.2443 15.0014C17.624 15.7748 16 17 16 18.5V21C16 22.1046 15.1046 23 14 23H10C8.89543 23 8 22.1046 8 21V18.5C8 17 6.37458 15.7736 5.75395 14.9992ZM13 10.0048H15.5L11 16.0048V12.0048H8.5L13 6V10.0048Z"></path></svg>
                <h2 className="text-xl font-bold text-slate-700 leading-tight mb-2">Wie können wir helfen?</h2>
                <p className="text-base text-slate-500 leading-snug xl:mb-10">
                    Obwohl wir ständig an der Verbesserung von Booxboo arbeiten, läuft vielleicht nicht immer alles nach Ihren Vorstellungen. Wir sind dankbar für Ihr Feedback und Ihre Anregungen.
                </p>
            </aside>
            <div className="flex-1 px-8 py-10">
                <form onSubmit={handleSubmit}>
                    <h3 className='mb-5'>Wie können wir Booxboo noch besser machen?</h3>
                    <Textarea value={description} rows={10} onChange={e => setDescription(e.target.value)} placeholder='Bitte beschreiben Sie Ihr Anliegen so ausführlich wie möglich.' />
                    <label className='flex items-center my-6'>
                        <input type="checkbox" className='mr-2 rounded text-slate-700' checked={attachScreenshot} onChange={e => setAttachScreenshot(e.target.checked)} />
                        <span className='text-gray-500'>Screenshot der aktuellen Seite anhängen</span>
                    </label>
                    <div className="flex items-center justify-between">
                        <Button type="button" variant='outline' onClick={handleClose}>Abbrechen</Button>
                        <Button type="submit" disabled={description.length == 0 || isSubmitting}>{isSubmitting ? 'Bitte warten' : 'Absenden'}</Button>
                    </div>
                </form>
            </div>
        </DialogContent>
    </Dialog>;
};

export default BugDialog;