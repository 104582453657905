import React, { useState, useEffect } from 'react';
import SemVer from 'semver';
import log from '/changelog.json';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from './ui/dialog';
import { Button } from './ui/button';

const ChangelogModal = () => {
    const [showModal, setShowModal] = useState(false);
    const [changelog, setChangelog] = useState({});

    useEffect(() => {
        const getChangelog = async () => {
            let localVersion = await localStorage.getItem('lastVersion');
            const localLogs = {};

            if (localVersion === null) {
                localVersion = Object.keys(log)[1];
            }

            for (const version in log) {
                if (localVersion === null || SemVer.lt(localVersion, version)) {
                    localLogs[version] = log[version];
                }
            }

            setChangelog(localLogs);

            if (Object.keys(localLogs).length > 0) setShowModal(true);
        }

        getChangelog();
    }, []);
    
    const closeModal = () => {
        localStorage.setItem('lastVersion', Object.keys(changelog)[0]);
        setShowModal(false);
    }
    
    return (
        <Dialog open={showModal} onOpenChange={closeModal}>
            <DialogContent className='max-w-xl'>
                <DialogHeader>
                    <DialogTitle className='text-xl'>Update - Version { Object.keys(changelog)[0] }</DialogTitle>
                    <DialogDescription>Booxboo wurde auf die neueste Version aktualisiert. Nachfolgend sehen Sie, was es Neues gibt:</DialogDescription>
                </DialogHeader>
               
                { Object.keys(changelog).map((version, index) => (
                    <div key={index}>
                        <h2 className="text-xl font-bold mb-3 text-primary">Version { version }</h2>
                        <ul className='mb-6 text-base leading-tight space-y-2 ml-7 list-outside' style={{ listStyleType: 'square'}}>
                            { changelog[version].map((entry, index) => (
                                <li key={index}>{ entry }</li>
                            )) }
                        </ul>
                    </div>
                )) }

                <DialogFooter>
                    <Button onClick={closeModal}>Schließen</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}

export default ChangelogModal;