import classNames from 'classnames';
import React, { useMemo } from 'react';
import { bookStatus } from '../utils/types';

type BookStatusProps = {
    status: string;
    style: "badge" | "text" | "dot";
    className?: string;
};

const BookStatus = ({ style = 'text', status, className }: BookStatusProps): React.ReactElement => {

    const statusColor = useMemo(() => {
        switch (status) {
            case 'available':
                return 'green';
            case 'shortage':
            case 'out_of_stock':
            case 'new_edition':
                return 'yellow';
            case 'discontinued':
            case 'blocked':
            case 'non-web':
                return 'red';
            default:
                return 'slate';
        }
    }, [status]);
    
    /* Let Tailwind pickup the color from the statusColor variable */
    const dummyColors = <span className="bg-green-500 bg-yellow-500 bg-red-500 bg-slate-500 text-green-500 text-yellow-500 text-red-500 text-slate-500"></span>;

    return <span className={classNames(`
        ${ style === 'badge' ? 'px-2 py-0.5 text-sm rounded-full bg-' + statusColor + '-500 text-white' : '' }
        ${ style === 'text' ? `text-${statusColor}-500` : '' }
        ${ style === 'dot' ? 'inline-flex items-center' : '' }
    `, className)}>
        {style === 'dot' && <span className={`w-2 h-2 mr-2 rounded-full bg-${ statusColor }-500`}></span>}
        {bookStatus[status]}
    </span>
}

export default BookStatus;